<template>
  <div class="max-w-7xl mx-auto pt-12 pb-24 px-4 bg-white sm:px-6 lg:px-8">
    <p class="mb-12 max-w-2xl text-xl text-gray-500">Our plans scale with your needs. Upgrade or downgrade at any time.</p>

    <div class="grid justify-items-center">
      <SwitchGroup as="div" class="flex items-center">
        <Switch v-model="yearlyEnabled" :class="[yearlyEnabled ? 'bg-indigo-600' : 'bg-gray-200', 'relative inline-flex flex-shrink-0 h-6 w-11 border-2 border-transparent rounded-full cursor-pointer transition-colors ease-in-out duration-200 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500']">
          <span aria-hidden="true" :class="[yearlyEnabled ? 'translate-x-5' : 'translate-x-0', 'pointer-events-none inline-block h-5 w-5 rounded-full bg-white shadow transform ring-0 transition ease-in-out duration-200']" />
        </Switch>
        <SwitchLabel as="span" class="ml-3">
          <span class="text-sm font-medium text-gray-900">Annual billing </span>
          <span class="text-sm text-gray-500">(Save 33%)</span>
        </SwitchLabel>
      </SwitchGroup>
    </div>

    <div v-if="loadingState === 'loading'" class="flex justify-center">
      <h3 class="bg-yellow-50 p-4 my-12 max-w-lg rounded">
        <svg class="mr-2 animate-spin inline h-5 w-5 text-indigo" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24">
          <circle class="opacity-25" cx="12" cy="12" r="10" stroke="currentColor" stroke-width="4"></circle>
          <path class="opacity-75" fill="currentColor" d="M4 12a8 8 0 018-8V0C5.373 0 0 5.373 0 12h4zm2 5.291A7.962 7.962 0 014 12H0c0 3.042 1.135 5.824 3 7.938l3-2.647z"></path>
        </svg>
        Loading...
      </h3>
    </div>
    <div v-else-if="loadingState === 'error'" class="flex justify-center">
      <h3 class="bg-red-100 p-4 my-12 max-w-lg rounded">
        We ran into an internal error :(
      </h3>
    </div>
    <div v-else class="mt-14 space-y-12 lg:space-y-0 lg:grid lg:grid-cols-3 lg:gap-x-8">
      <div v-for="plan in currentPlans" :key="plan.name" class="relative p-8 bg-white border border-gray-200 rounded-2xl shadow-sm flex flex-col">
        <div class="flex-1">
          <h3 class="text-xl font-semibold text-gray-900">{{ plan.name }}</h3>
          <p v-if="plan.most_popular" class="absolute top-0 py-1.5 px-4 bg-indigo-500 rounded-full text-xs font-semibold uppercase tracking-wide text-white transform -translate-y-1/2">Most popular</p>
          <p class="mt-4 flex items-baseline text-gray-900">
            <span v-if="plan.frequency === 'year'" class="text-5xl font-extrabold tracking-tight">${{ plan.price / 12 }}</span>
            <span v-else class="text-5xl font-extrabold tracking-tight">${{ plan.price }}</span>
            <span v-if="plan.price === 0" class="ml-1 text-xl font-semibold">/ forever</span>
            <span v-else class="ml-1 text-xl font-semibold">/ month</span>
          </p>

          <ul role="list" class="mt-6 space-y-6">
            <li class="flex">
              <CheckIcon class="flex-shrink-0 w-6 h-6 text-indigo-500" aria-hidden="true" />
              <span class="ml-3 text-gray-500">{{ plan.message_quota }} emails/month</span>
            </li>
            <li class="flex">
              <CheckIcon class="flex-shrink-0 w-6 h-6 text-indigo-500" aria-hidden="true" />
              <span class="ml-3 text-gray-500">{{ plan.address_quota }} @blockbase -> Notion DB connection<span v-if="plan.address_quota > 1">s</span>
              </span>
            </li>
            <li class="flex">
              <CheckIcon class="flex-shrink-0 w-6 h-6 text-indigo-500" aria-hidden="true" />
              <span class="ml-3 text-gray-500">{{ plan.support }}</span>
            </li>
          </ul>
        </div>

        <div v-if="$auth.user.value.plan.name === plan.name" class="bg-gray-300 text-gray-700 mt-8 block w-full py-3 px-6 border border-transparent rounded-md text-center font-medium">
          Your Current Plan
        </div>
        <button v-else @click="doUpgrade(plan)" :class="[plan.mostPopular ? 'bg-indigo-500 text-white hover:bg-indigo-600' : 'bg-indigo-50 text-indigo-700 hover:bg-indigo-100', 'mt-8 block w-full py-3 px-6 border border-transparent rounded-md text-center font-medium']">
          <span v-if="plan.price < $auth.user.value.plan.price">
            Downgrade now
          </span>
          <span v-else>
            Upgrade now
          </span>
        </button>
      </div>
    </div>
  </div>
</template>

<script>
import { CheckIcon } from '@heroicons/vue/outline'
import { loadStripe } from '@stripe/stripe-js'
import { Switch, SwitchGroup, SwitchLabel } from '@headlessui/vue'
import * as Sentry from '@sentry/browser'

export default {
  components: {
    CheckIcon,
    Switch,
    SwitchGroup,
    SwitchLabel,
  },
  data () {
    return {
      plans: null,
      yearlyEnabled: true,
      loadingState: 'loading',
    }
  },
  computed: {
    currentPlans() {
      var curPlans = []
      if (this.plans) {
        var currentFrequency = 'month'
        if (this.yearlyEnabled) {
          currentFrequency = 'year'
        }
        for (var plan of this.plans) {
          if (plan.name === 'Free' || plan.frequency === currentFrequency) {
            curPlans.push(plan)
          }
        }
      }
      return curPlans
    }
  },
  async mounted () {
    await this.getPlans()
  },
  methods: {
    async getPlans() {
      this.loadingState = 'loading'
      try {
        const result = await this.axios.get(process.env.VUE_APP_API_URL + 'plans',
          { headers: { authorization: 'Bearer ' + this.$auth.token.value } }
        )
        this.plans = result.data
      } catch (err) {
        this.loadingState = 'error'
        Sentry.captureException(new Error(`Profile plans GET: ${err}`))
        return
      }
      this.loadingState = 'ready'
    },
    async doUpgrade(plan) {
      this.loadingState = 'loading'
      var stripeInfo = null
      const post = {
        price: plan.stripe_price_id
      }
      try {
        stripeInfo = await this.axios.post(process.env.VUE_APP_API_URL + 'startupgrade', post,
          { headers: { authorization: 'Bearer ' + this.$auth.token.value } }
        )
      } catch (err) {
        this.loadingState = 'error'
        Sentry.captureException(new Error(`Profile upgradePlan POST: ${err}`))
        return
      }
      document.location = stripeInfo.data.url
    },
  }
}
</script>
